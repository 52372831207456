import axios from "axios";
import { configure } from "axios-hooks";
import { API_BASE_URL, IS_SUBDOMAIN } from "configs/AppConfig";
import history from "../history";
import { notification } from "antd";
import {
  PUBLIC_REQUEST_KEY,
  ENTRY_ROUTE,
  TOKEN_PAYLOAD_KEY,
  AUTH_TOKEN_KEY,
} from "../constants/common.constant";
import { GetLocalStorage } from "../utils/common";

const fetch_ = axios.create({
  baseURL: `${API_BASE_URL}api/v1`,
  timeout: 60000,
});

configure({ axios: fetch_ });

// API Request interceptor
fetch_.interceptors.request.use(
  async (config) => {
    // console.log("🚀 ~ config:", config);
    const jwtToken = GetLocalStorage(AUTH_TOKEN_KEY);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      const hostname = window.location.hostname; // Get the full hostname from the URL
      const hostnameParts = hostname.split(".");
      console.log(hostnameParts[0].includes("business"));
      if (!IS_SUBDOMAIN || hostnameParts[0].includes("business")) {
        history.push(ENTRY_ROUTE);
      }

      // window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    // notification.error({
    //   message: "Error",
    // });
    Promise.reject(error);
  }
);

// API respone interceptor
fetch_.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.message) {
      notification.success({ description: data.message });
    }
    return data;
  },
  (error) => {
    console.log("error from api", error);
    const { data = {}, status, statusText } = error?.response || {};
    const notificationData = {
      description: data?.error?.message || statusText,
      message: data?.error?.error || statusText,
      statusCode: data.statusCode || status,
    };

    if (
      [401, 403].includes(notificationData.statusCode) &&
      ![API_BASE_URL.LOGIN, API_BASE_URL.SEND_OTP].includes(
        error.response?.config?.url
      )
    ) {
      // RemoveLocalStorage(AUTH_TOKEN_KEY)
      // history.push(ENTRY_ROUTE);
      // window.location.reload();
    }

    // notification.error(notificationData);

    return Promise.reject(notificationData);
  }
);

export default fetch_;

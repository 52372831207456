import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { NAV_PATH } from "configs/NavigationConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${NAV_PATH.dashboard}`}
          exact
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${NAV_PATH.personal}`}
          exact
          component={lazy(() => import(`./personal`))}
        />
        <Route
          exact
          path={NAV_PATH.scholarships.root}
          component={lazy(() => import(`./scholarships/scholarships-list`))}
        />
        <Route
          exact
          path={`${NAV_PATH.scholarships.detail(`:id`)}`}
          component={lazy(() => import(`./scholarships/scholarship-detail`))}
        />
        <Route
          exact
          path={`${NAV_PATH.scholarships.edit(`:id`)}`}
          component={lazy(() => import(`./scholarships/scholarships-tab`))}
        />
        <Route
          exact
          path={NAV_PATH.scholarships.wizard}
          render={() => <Redirect to={NAV_PATH.scholarships.template} />}
        />
        <Route
          path={NAV_PATH.scholarships.template}
          exact
          component={lazy(() => import(`./scholarships/scholarship-wizard`))}
        />
        <Route
          path={NAV_PATH.scholarships.winners_list(":scholarshipId")}
          exact
          component={lazy(() => import(`./winner`))}
        />
        <Route
          path={NAV_PATH.scholarships.applicant_detail(":scholarshipId")}
          exact
          component={lazy(() => import(`./applicants`))}
        />

        <Route
          path={NAV_PATH.scholarships.rank_criteria(":scholarshipId")}
          exact
          component={lazy(() => import(`./rank-criteria`))}
        />

        <Route
          path={NAV_PATH.applicants.detail(":scholarshipId", ":applicantId")}
          exact
          component={lazy(() => import(`./applicants/review`))}
        />
        <Route
          component={lazy(() => import(`./scholarships/scholarship-wizard`))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);

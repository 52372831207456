import { API_PATH } from "../constants/common.constant";
import fetch_ from "../interceptors/FetchInterceptor";

const {
  SCHOLARSHIP_DETAIL,
  SCHOLARSHIP_DETAIL_BY_ID,
  SCHOLARSHIP_APPLICANTS,
  SCHOLARSHIP_WINNERS,
  SCHOLARSHIP_APPLICANTS_EXPORT,
  SUBMIT_APPLICATION,
  SCHOLARSHIP_LIST,
  GET_SCHOLARSHIP_REQUIREMENTS,
  GET_SCHOLARSHIP_CRITERIA,
  DELETE_CRITERIA,
} = API_PATH;

export const ScholarshipService_ = {
  getScholarship: (slug) => {
    return fetch_({
      url: SCHOLARSHIP_DETAIL(slug),
      method: "GET",
    });
  },
  getScholarshipRequirements: (scholarshipId) => {
    return fetch_({
      url: GET_SCHOLARSHIP_REQUIREMENTS(scholarshipId),
      method: "GET",
    });
  },
  getScholarshipCriteria: (scholarshipId) => {
    return fetch_({
      url: GET_SCHOLARSHIP_CRITERIA(scholarshipId),
      method: "GET",
    });
  },
  getScholarshipById: (id) => {
    return fetch_({
      url: SCHOLARSHIP_DETAIL_BY_ID(id),
      method: "GET",
    });
  },
  getScholarshipApplicants: (scholarshipId) => {
    return fetch_({
      url: SCHOLARSHIP_APPLICANTS(scholarshipId),
      method: "GET",
    });
  },
  getScholarshipWinners: (scholarshipId) => {
    return fetch_({
      url: SCHOLARSHIP_WINNERS(scholarshipId),
      method: "GET",
    });
  },
  getScholarshipApplicantsExport: (scholarshipId) => {
    return fetch_({
      url: SCHOLARSHIP_APPLICANTS_EXPORT(scholarshipId),
      method: "GET",
    });
  },
  getScholarships: (query) => {
    return fetch_({
      url: SCHOLARSHIP_LIST,
      method: "GET",
      params: query,
    });
  },
  submitApplication: (scholarshipId, data) => {
    return fetch_({
      url: SUBMIT_APPLICATION(scholarshipId),
      method: "POST",
      data,
    });
  },

  createScholarshipCriteria: (scholarshipId, data) => {
    return fetch_({
      url: GET_SCHOLARSHIP_CRITERIA(scholarshipId),
      method: "POST",
      data,
    });
  },

  deleteScholarshipCriteria: (scolarshipId, scholarshipCriteriaId) => {
    return fetch_({
      url: DELETE_CRITERIA(scolarshipId, scholarshipCriteriaId),
      method: "DELETE",
    });
  },
};

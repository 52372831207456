import React, { useEffect, useState } from "react";
import "./scolarship-fields.style.css";
import { Badge } from "antd";
import moment from "moment";
import "./scholarship-countdown.style.css";

const ScholarshipCountDown = ({
  scholarshipInfo,
  timeFinished,
  style,
  mainStyle,
}) => {
  const [countdown, setCountdown] = useState(null);
  useEffect(() => {
    const start = moment(scholarshipInfo?.recurrenceConfig?.start);
    const deadline = moment(scholarshipInfo?.recurrenceConfig?.deadline);
    if (start.isBefore() && deadline.isAfter()) {
      calculateTime(deadline);
      const myInterval = setInterval(() => {
        calculateTime(deadline);
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [scholarshipInfo]);

  const calculateTime = (deadline) => {
    const now = moment();
    const remaining = deadline.utcOffset(scholarshipInfo?.timezone) - now;
    if (remaining <= 0) {
      timeFinished();
    }
    const duration = moment.duration(remaining);
    setCountdown(duration);
  };

  return (
    countdown && (
      <>
        <span
          className="countdown-block"
          style={{
            ...mainStyle,
          }}
        >
          <p
            style={{
              ...style,
            }}
            className="time"
          >
            {countdown?.asDays()?.toFixed(0)}
          </p>
          <p
            style={{
              ...style,
            }}
          >
            DAYS
          </p>
        </span>
        <span
          className="countdown-block"
          style={{
            ...mainStyle,
          }}
        >
          <p
            style={{
              ...style,
            }}
            className="time"
          >
            {countdown?.hours()}
          </p>
          <p
            style={{
              ...style,
            }}
          >
            HOURS
          </p>
        </span>
        <span
          className="countdown-block"
          style={{
            ...mainStyle,
          }}
        >
          <p
            style={{
              ...style,
            }}
            className="time"
          >
            {countdown?.minutes()}
          </p>
          <p
            style={{
              ...style,
            }}
          >
            MINUTES
          </p>
        </span>
        <span
          className="countdown-block"
          style={{
            ...mainStyle,
          }}
        >
          <p
            style={{
              ...style,
            }}
            className="time"
          >
            {countdown?.seconds()}
          </p>
          <p
            style={{
              ...style,
            }}
          >
            SECONDS
          </p>
        </span>
      </>
    )
  );
};

export default ScholarshipCountDown;

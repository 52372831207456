import "./scolarship-fields3.style.css";
import ScholarshipInput from "./component/ScholarshipInput.js";
const ScholarshipFields3 = ({ scholarshipInfo, buttonStyle, termsStyle }) => {
  return (
    <ScholarshipInput
      scholarshipInfo={scholarshipInfo}
      buttonStyle={buttonStyle}
      termsStyle={termsStyle}
    />
  );
};

export default ScholarshipFields3;

import { Modal, message } from "antd";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import { DeleteOutlined, FileFilled } from "@ant-design/icons";
import moment from "moment";
import DOMPurify from "dompurify";
import { CommonService_ } from "services/common.service";
import { GetJson } from "./common";

export const baseFilterCriteria = (config) => {
  const baseFilter = {
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
    search: "",
    orderBy: "createdAt",
    order: "DESC",
    where: {},
  };
  return baseFilter;
};

export const deleteHandler = ({ onOk, subject }) => {
  Modal.confirm({
    title: "Confirm",
    icon: <DeleteOutlined />,
    content: `Are you sure to delete the ${subject}`,
    okText: "Yes",
    cancelText: "No",
    onOk: onOk,
  });
};

export const text = (text) => {
  return text;
};

export const getemail = (email) => {
  return email;
};

export const getcountry = (country) => {
  return country;
};

export const gettel = (tel) => {
  return tel;
};

export const parseDMY = (s) => {
  let [d, m, y] = s.split(/\D/);
  return new Date(y, m - 1, d);
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function beforeUpload(file) {
  const isAllowedFile = true; //['image/jpeg', 'image/png'].includes(file.type);
  if (!isAllowedFile) {
    message.error("You can only upload allowed file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("File must be smaller than 2MB!");
  }
  return isAllowedFile && isLt2M;
}

export const getFieldFormattedValue = async (value, fieldType) => {
  let formattedValue = "";
  switch (fieldType) {
    case "date":
      formattedValue = moment(value).format("DD/MM/YYYY");
      break;
    case "editor":
      formattedValue = (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(value),
          }}
        />
      );
      break;
    case "file":
      {
        const link = await getFileUrl(GetJson(value));
        formattedValue = (
          <a href={link} target="_blank">
            <FileFilled />
          </a>
        );
      }
      break;
    case "textarea":
    case "option":
    case "checkbox":
    case "link":
    case "email":
    case "phone":
    case "number":
    default:
      formattedValue = value;
  }

  return formattedValue;
};

export const getFileUrl = async (value) => {
  const data = await CommonService_.getPublicLink(value);
  return data.data?.publicUrl ?? null;
};

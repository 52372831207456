/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from "@casl/ability";
import { authInitState } from "redux/reducers/Auth";
import store from "../redux/store";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth;

  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth?.access ?? []));
  }
});

try {
  const currentPermissions = authInitState?.access ?? [];
  ability.update(defineRulesFor(currentPermissions));
} catch (e) {
  console.log(e);
}

function defineRulesFor(auth) {
  const { can, rules, cannot } = new AbilityBuilder();
  auth.permissions?.forEach((ability) => {
    const [action, ...subject] = ability.split("_");
    can(action, subject.join("_"));
  });
  return rules;
}

export default ability;

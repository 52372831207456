import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Text from "antd/lib/typography/Text";
import ScholarshipFields from "./scolarship-fields";
import ScholarshipCountDown from "./scholarship-countdown";
import {
  Title,
  DontWait,
  Header,
  Passage,
  Award,
  Description,
} from "./component/Title.js";
import { Footer } from "./component/Footer.js";
import moment from "moment";

const Theme1 = ({ scholarshipInfo, setScolarshipInfo }) => {
  const mobileWidth = window.innerWidth < 750;

  const renderRightScreen = () => {
    if (moment(scholarshipInfo?.recurrenceConfig?.start).isAfter()) {
      return (
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">
              This scholarship hasn’t been launched yet. We’ll remind you when
              it's open for applications.
            </h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else if (moment(scholarshipInfo?.recurrenceConfig?.deadline).isBefore()) {
      return (
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">This scholarship has been closed.</h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <DontWait
            text={"Don't wait, apply noooow!"}
            subText={"We'll stop accepting applications in:"}
            style={{
              color: "#FFFFFF",
            }}
          />
          <ScholarshipCountDown
            scholarshipInfo={scholarshipInfo}
            timeFinished={() => setScolarshipInfo(scholarshipInfo)}
            style={{
              color: "#000000",
            }}
            mainStyle={{
              background: "#FFFFFF",
            }}
          />
          <div style={{ paddingTop: "20px" }}>
            <ScholarshipFields scholarshipInfo={scholarshipInfo} />
          </div>
          <Passage
            style={{
              color: "#FFFFFF",
            }}
            text={`The worthy is operated by ${
              scholarshipInfo?.user?.firstName
            } and runs from ${moment(
              scholarshipInfo?.recurrenceConfig?.start ?? new Date()
            ).format("MMMM DD, YYYY")} to ${moment(
              scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
            ).format(
              "MMMM DD, YYYY"
            )} . All students who are at least 16 years old and are legal resident of United States or India are eligible (additional may apply). Void where prohibited by law. No purchase necessary to enter or win.`}
          />
        </>
      );
    }
  };

  return (
    <>
      <div className={`h-100`} style={{}}>
        <Header />
      </div>
      <Row>
        <Col
          style={{
            padding: "30px",
            backgroundImage: "url(/new/theme1.png)",
            width: mobileWidth ? "100%" : "60%",
          }}
        >
          <Title
            scholarshipInfo={scholarshipInfo}
            style={{
              color: "#FFFFFF",
            }}
          />
          {renderRightScreen()}
        </Col>
        <Col style={{ width: mobileWidth ? "100%" : "40%", padding: "20px" }}>
          <Award scholarshipInfo={scholarshipInfo} />

          <Description scholarshipInfo={scholarshipInfo} />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Theme1;

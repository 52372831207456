import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./redux/store";
import Views from "./views";
import { THEME_CONFIG } from "./configs/AppConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./interceptors/FetchInterceptor";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="965974145618-161g8j8q9l3lt9tcru5nq66ksvs5e93q.apps.googleusercontent.com">
        <Provider store={store}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Suspense fallback={null}>
              <Router>
                <Switch>
                  <Route
                    path={`/homepage`}
                    component={lazy(() =>
                      import(`./views/auth-views/homepage`)
                    )}
                  />
                  <Route
                    path={`/brands`}
                    component={lazy(() =>
                      import(`./views/auth-views/menuPages/Brands.js`)
                    )}
                  />
                  <Route
                    path={`/colleges`}
                    component={lazy(() =>
                      import(`./views/auth-views/menuPages/Colleges.js`)
                    )}
                  />
                  <Route
                    path={`/schools`}
                    component={lazy(() =>
                      import(`./views/auth-views/menuPages/Schools.js`)
                    )}
                  />
                  <Route
                    path={`/nonProfitOrganisation`}
                    component={lazy(() =>
                      import(
                        `./views/auth-views/menuPages/NonProfitOrganisation.js`
                      )
                    )}
                  />
                  <Route
                    path={`/clubs`}
                    component={lazy(() =>
                      import(`./views/auth-views/menuPages/Clubs.js`)
                    )}
                  />
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </Suspense>
          </ThemeSwitcherProvider>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
